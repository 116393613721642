import { equalTo, orderByChild, query, ref } from 'firebase/database'
import { useFirebaseValue } from '/machinery/useFirebaseDatabase'
import { statements } from '/data/statements'
import { Loader } from '/features/buildingBlocks/Loader'
import { MiniCard, MiniCardList } from '/features/buildingBlocks/MiniCard'

export function SelectedStatementsOverview() {
  const { data, isFetching } = useFirebaseValue({
    reference: ({ user, database }) => query(
      ref(database, `/users/${user.uid}/statements`),
      orderByChild('subjectToDiscus'),
      equalTo(true)
    )
  })

  const entries = data ? mapStatementsToTheSelectedEntries(data) : null

  if (isFetching) return <Loader />

  return (
    <MiniCardList>
      {Object.values(entries).map(x => {
        return (
          <MiniCard key={x.id}>
            {x.statement}
          </MiniCard>
        )
      })}
    </MiniCardList>
  )

  function mapStatementsToTheSelectedEntries(data) {
    return Object.keys(data).reduce(
      (result, key) => {
        result[key] = statements[key]
        return result
      },
      {}
    )
  }
}
