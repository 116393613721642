import { SelectedStatementsOverview } from './SelectedStatementsOverview'
import { FirebaseContext } from '/domain/FirebaseContext'

export default function SelectedStatementsOverviewApp() {
  return (
    <FirebaseContext>
      <SelectedStatementsOverview />
    </FirebaseContext>
  )
}
